.footer {
	background-color: #000;
	color: #fff;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	padding-bottom: 20px;
}

.footerSocial {
	margin: 50px 0 20px;
	font-size: 22px;
}

.footerIcon {
	margin: 0 15px;
	font-size: 24px;
	color: #fff;
}