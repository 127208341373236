.about{
	margin: auto;
	min-height: 650px;
	max-width: 1140px;
}

.about_dark {
	background-color: #7f7f7f;
}

.aboutTitle{
	padding: 80px 0;
	letter-spacing: 3px;
}

.aboutTitle_dark {
	padding: 80px 0;
	color: #fff;
}

.content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}
.image {
	min-width: 260px;
	min-height: 355px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
}
.image_dark {
	background-color: #6d6d6d;
	min-width: 260px;
	min-height: 355px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
}

.thumbnail {
	padding: 10px;
	height: 300px;
}
.skillLogo {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}
.intro {
	max-width: 600px;
	min-height: 250px;
	border: 1px solid rgba(0,0,0,.125);
}

.intro_dark {
	background-color: #6d6d6d;
	max-width: 600px;
	min-height: 250px;
	border: 1px solid rgba(0,0,0,.125);
}

.introHeader {
	width: 100%;
	height: 35px;
	background-color: rgba(0,0,0,.03);
	border-bottom: 1px solid rgba(0,0,0,.125);
	display: flex;
	flex-direction: row;
	align-items: center;
}
.redCircle {
	width: 10px;
	height: 10px;
	background-color: rgb(237, 76, 92);
	border-radius: 50%;
	margin: 0 4px 0 14px;
}
.yellowCircle {
	width: 10px;
	height: 10px;
	background-color: rgb(253, 203, 88);
	border-radius: 50%;
	margin: 4px;
}
.greenCircle {
	width: 10px;
	height: 10px;
	background-color: rgb(120, 177, 89);
	border-radius: 50%;
	margin: 4px;
}
.introTitle{
	padding-bottom: 10px;
}
.introTitle_dark {
	padding-bottom: 10px;
	color: #fff;
}
.myself{
	line-height: 200%;
	padding: 25px 20px 20px;
	text-align: left;
}
.introContent {
	font-size: 16px;
}
.introContent_dark {
	font-size: 16px;
	color: #fff;
}

@media (max-width: 1024px) {
	.image, .image_dark {
		margin-bottom: 30px;
	}
	.intro, .intro_dark {
		margin: 0 20px 40px;
	}
}
