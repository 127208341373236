.header_light {
    background-color: #e9d5a1;
    height: 100vh;
		font-size: 130%;
		display: flex;
		flex-direction: column;
		justify-content: center;
}

.header_dark {
	background-color: #494949;
	height: 100vh;
	font-size: 130%;
	color: #fff;
	display: flex;
	flex-direction: column;
		justify-content: center;
}

.head {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.github a:hover{
	color: white;
}

.logo {
    width: 150px;
    height: 150px;
}

.headerLogo {
	max-height: 100%;
}

.logoText_light {
	color: black;
}

.logoText_dark {
	color: #fff;
}

.switch {
	position: absolute;
	right: 20px;
	top: 10px;
}

/* Github Animated Logo */
.githubCorner {
    fill: #151513;
    color: #fff;
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    transform: scale(-1, 1);
}

.octoArm {
    transform-origin: 130px 106px;
}

.githubCorner:hover .octoArm {
    animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
    0%,
    100% {
        transform: rotate(0);
    }
    20%,
    60% {
        transform: rotate(-25deg);
    }
    40%,
    80% {
        transform: rotate(10deg);
    }
}

@media (max-width: 500px) {
    .githubCorner:hover .octoArm {
        animation: none;
    }
    .githubCorner .octoArm {
        animation: octocat-wave 560ms ease-in-out;
    }
}
